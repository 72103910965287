<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/forgot-password.png"
                alt="login"
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white"
            >
              <div class="p-8">
                <div class="vx-card__title mb-6">
                  <h4 class="mb-4">
                    {{ lang.setPassword.title[languageSelected] }}
                  </h4>
                  <p>{{ lang.setPassword.description[languageSelected] }}</p>
                </div>
                <vs-input
                  type="password"
                  :label-placeholder="
                    lang.setPassword.form.newPassword[languageSelected]
                  "
                  v-model="newPassword"
                  class="w-full mt-0 mb-6"
                  :danger="newPasswordError !== null"
                  :dangerText="newPasswordError"
                />
                <vs-input
                  type="password"
                  :label-placeholder="
                    lang.setPassword.form.repeatNewPassword[languageSelected]
                  "
                  v-model="repeatNewPassword"
                  class="w-full mb-8"
                  :danger="repeatNewPasswordError !== null"
                  :dangerText="repeatNewPasswordError"
                />

                <vs-button
                  type="border"
                  to="/pages/login"
                  class="px-4 w-full md:w-auto"
                >
                  {{ lang.setPassword.backToLogin[languageSelected] }}
                </vs-button>

                <vs-button
                  :disabled="
                      !newPassword ||
                      newPassword !== repeatNewPassword
                  "
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  @click="setPassword"
                >
                  {{ lang.setPassword.changePassword[languageSelected] }}
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import konecta from '@/services/konecta'

import { sha256 } from 'js-sha256'

export default {
  name: 'SetPassword',
  data() {
    return {
      newPassword: '',
      repeatNewPassword: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    newPasswordError() {
      if (
        this.newPassword.trim() // para que no tire error desde el principio
        && !this.validPassword(this.newPassword)
      ) {
        return this.lang.accountSetup.form.error
          .newPassword_invalid[this.languageSelected]
      }
      return null
    },
    validRepeatNewPassowrd() {
      return (
        this.repeatNewPassword === '' ||
        (this.newPassword &&
          this.newPassword === this.repeatNewPassword)
      )
    },
    repeatNewPasswordError() {
      if (
        this.newPassword !== '' &&
        this.repeatNewPassword !== '' &&
        this.newPassword !== this.repeatNewPassword
      ) {
        return this.lang.setPassword.form.error
          .newPassword_neq_repeatNewPassword[this.languageSelected]
      }
      return null
    }
  },
  methods: {
    validPassword(password) {
      return password
        && password.trim().length >= 8
    },
    setPassword() {
      konecta
        .post('/user/set-password/', {
          token: this.$route.params.token,
          newPassword: sha256(this.newPassword)
        })
        .then(() => {
          this.$vs.notify({
            title: this.lang.setPassword.form.success.title[
              this.languageSelected
            ],
            text: this.lang.setPassword.form.success.text[
              this.languageSelected
            ],
            color: 'success'
          })
          this.$router.push('/pages/login')
        })
        .catch(err => {
          if (
            err
            && err.response
            && err.response.status === 400
            && err.response.data
            && err.response.data === 'token has expired'
          ) {
            this.$vs.notify({
              title: 'Oops!',
              text: this.lang.setPassword.form.error.tokenHasExpired[
                this.languageSelected
              ],
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Oops!',
              text: this.lang.setPassword.form.error.generic[
                this.languageSelected
              ],
              color: 'danger'
            })
          }
        })
    }
  },
  mounted() {
    this.$vs.loading.close('body > .con-vs-loading')
  }
}
</script>
